<template>
  <div class="home-container">
    <div class="banner">
      <img src="../assets/img/banner.png" alt="" class="banner-img" />
      <el-button size="large" type="primary" class="sub-btn" @click="Subscribe"
        >Subscribe</el-button
      >
    </div>
    <div class="service">
      <div class="service-left">
        <div class="left-title">Service Highlights</div>
        <div class="dl">
          <div class="dl-title">Content First</div>
          <div class="dl-content">
            Whether you are pursuing your personal dream or promoting a brand,
            we believe that content is the core competitiveness of creators. We
            encourage creators to utilize their creativity to create unique
            content and create in-depth interaction for their fans.
          </div>
        </div>
        <div class="dl">
          <div class="dl-title">A real team on your side</div>
          <div class="dl-content">
            Our dedicated team of professionals will be on your side to support
            you and drive your success across multiple platforms, taking a
            professional approach to ensure your voice reaches further. We
            understand the needs of every creator and are committed to being
            your digital partner.
          </div>
        </div>
      </div>
      <div class="service-right">
        <img src="../assets/img/service.png" alt="" />
      </div>
    </div>
    <div class="business">
      <div class="business-box">
        <div class="box-title">Our Business</div>
        <div class="box-list">
          <p class="item">
            Growth Analysis Report and One-on-One Advice: Through our exclusive
            Growth Analysis Report, we provide you with a tailor-made strategy,
            while our customer service team will provide you with personalized
            advice to help you continue to improve.
          </p>
          <p class="item">
            Practical copyright management services: We provide practical
            copyright management services used by the world's top brands to
            ensure that your creations are not infringed upon. You can
            concentrate on creating while we protect your unique ideas.
          </p>
          <p class="item">
            High-end marketing team: Our high-end marketing team will work hard
            to build your personal brand and make you stand out on social media
            through precise market positioning and innovative marketing
            strategies.
          </p>
          <p class="item">
            Support for every idea: We not only support your every idea, but
            also work to enhance the content richness of your IP, making your
            creations more engaging through strategic partnerships and
            innovative tools.
          </p>
        </div>
      </div>
    </div>
    <div class="creator">
      <div class="creator-box">
        <div class="box-title">Exclusive Benefits for Creators</div>
        <div class="box-list">
          <div class="item">
            <img src="../assets/img/c1.png" alt="" class="logo" />
            <p class="item-title">Account Operation Tools</p>
            <p class="item-desc">
              We provide the most advanced account operation tools to make
              creation a breeze. From fine data analysis to social media
              management, we make sure your account stays active and attracts
              more attention.
            </p>
          </div>
          <div class="item">
            <img src="../assets/img/c2.png" alt="" class="logo" />
            <p class="item-title">Copyright-free music, image, and video</p>
            <p class="item-desc">
              a rich library of resources to diversify your creations and
              attract more fans. We cooperate with excellent creators around the
              world to provide you with high-quality music, image and video
              materials.
            </p>
          </div>
          <div class="item">
            <img src="../assets/img/c3.png" alt="" class="logo" />
            <p class="item-title">Stable and reliable business cooperation</p>
            <p class="item-desc">
              We are committed to securing stable and reliable business
              cooperation opportunities for you, from branding to sponsorship,
              so that you can take your creative career to the next level.
            </p>
          </div>
          <div class="item">
            <img src="../assets/img/c4.png" alt="" class="logo" />
            <p class="item-title">Detailed Revenue Reporting</p>
            <p class="item-desc">
              Transparent financial reporting allows you to clearly understand
              the rewards of every effort. We will share detailed revenue
              reports with you so that you can better plan your future creative
              endeavors.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="choice">
      <div class="choice-box">
        <div class="box-title">Creator's Choice</div>
        <div class="box-list">
          <div class="line">
            <div class="item">
              <img src="../assets/img/icon1.png" alt="" class="icon" />
              <div class="desc">
                Get a deeper understanding of your fans and develop a more
                targeted development strategy. We will provide you with accurate
                fan profiles through data analysis and market research to help
                you better interact with your audience.
              </div>
              <div class="user">- Fan Analysis</div>
            </div>
            <div class="item">
              <img src="../assets/img/icon1.png" alt="" class="icon" />
              <div class="desc">
                Utilize our technology and experience to expand your video
                traffic and increase exposure. We will help your creations
                spread to the world more quickly through globalized promotion
                means.
              </div>
              <div class="user">- Video Traffic Expansion</div>
            </div>
          </div>
          <div class="line">
            <div class="item">
              <img src="../assets/img/icon1.png" alt="" class="icon" />
              <div class="desc">
                We will provide you with richer and higher-end business
                cooperation opportunities, and establish strategic partnerships
                with the world's first-class brands, so as to make your
                creations more commercially valuable.
              </div>
              <div class="user">
                - Richer and higher-end business cooperation
              </div>
            </div>
            <div class="item">
              <img src="../assets/img/icon1.png" alt="" class="icon" />
              <div class="desc">
                Join our community to share your experience with other
                outstanding creators, establish cooperative relationships, and
                grow together. We believe that together we can create a greater
                impact.
              </div>
              <div class="user">- Creators' community</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact">
      <p class="title1">Contact</p>
      <p class="title2">support@starbuzzhub.com</p>
      <p class="title3">
        Copyright 2024 StarbuzzHub. All Rights Reserved
        <span
          class="ys"
          style="
            margin-left: 16px;
            color: red;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
          "
          @click="toYs"
          >Service and Privacy Policy</span
        >
      </p>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const Subscribe = () => {
  window.open("http://eepurl.com/iGCKrE", "_self");
};
const toYs = () => {
  const route = router.resolve({
    path: "/policy",
  });
  window.open(route.href, "_blank");
};
</script>

<style lang="less" scoped>
.home-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fafafa;
  .banner {
    position: relative;
    &-img {
      width: 100%;
    }
    .sub-btn {
      position: absolute;
      left: 12.5vw;
      bottom: 12vw;
      width: 210px;
      height: 56px;
    }
  }
  .service {
    margin-top: 160px;
    display: flex;
    align-items: center;
    width: 1260px;
    margin-bottom: 120px;
    &-left {
      .left-title {
        font-size: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 56px;
        margin-bottom: 60px;
      }
      .dl {
        text-align: left;
        &:last-of-type {
          margin-top: 40px;
        }
        &-title {
          font-size: 24px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 33px;
          margin-bottom: 16px;
        }
        &-content {
          font-size: 24px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 33px;
        }
      }
    }
    &-right {
      margin-left: 30px;
      img {
        width: 606px;
        height: 597px;
      }
    }
  }
  .business {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #f6f7fa;
    &-box {
      width: 1260px;
      padding-top: 160px;
      .box-title {
        font-size: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000b33;
        line-height: 60px;
        text-align: center;
        margin-bottom: 100px;
      }
      .box-list {
        .item {
          font-size: 24px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: rgba(0, 11, 51, 0.8);
          line-height: 38px;
          margin-bottom: 60px;
          display: flex;
          &::before {
            content: "";
            width: 16px;
            height: 16px;
            background: #f8c23c;
            flex-shrink: 0;
            border-radius: 50%;
            margin-top: 10px;
            margin-right: 16px;
          }
          &:last-of-type {
            margin-bottom: 160px;
          }
        }
      }
    }
  }
  .creator {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #fafafa;
    &-box {
      padding-top: 160px;
      width: 1260px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .box-title {
        width: 593px;
        height: 60px;
        font-size: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000b33;
        line-height: 60px;
        margin-bottom: 100px;
      }
      .box-list {
        display: flex;
        align-items: center;
        margin-bottom: 160px;
        .item {
          flex: 1;
          background: #ffffff;
          border-radius: 5px;
          margin-right: 26px;
          height: 530px;
          cursor: pointer;
          transition: 0.3s all;
          &:hover {
            transform: translateY(-10px) scale(1.05);
          }
          &:last-of-type {
            margin-right: 0;
          }
          img {
            width: 100%;
            margin-bottom: 24px;
          }
          &-title {
            font-size: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #343434;
            line-height: 28px;
            margin-bottom: 24px;
            padding: 0 22px;
          }
          &-desc {
            width: 235px;
            height: 157px;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #747474;
            line-height: 22px;
            margin-bottom: 24px;
            padding: 0 22px;
          }
        }
      }
    }
  }
  .choice {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #f6f7fa;
    &-box {
      padding-top: 160px;
      width: 1260px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .box-title {
        font-size: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000b33;
        line-height: 60px;
      }
      .box-list {
        margin-top: 120px;
        width: 100%;
        .line {
          display: flex;
          justify-content: space-between;
          &:first-of-type {
            margin-bottom: 80px;
          }
          &:last-of-type {
            margin-bottom: 220px;
          }
          .item {
            width: 500px;
            .icon {
              width: 40px;
              height: 30px;
              margin-bottom: 30px;
            }
            .desc {
              font-size: 16px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #000b33;
              line-height: 26px;
              height: 130px;
              cursor: pointer;
              &:hover {
                color: #fd4c5c;
              }
            }
          }
        }
      }
    }
  }
  .contact {
    width: 100%;
    height: 360px;
    background: #343434;
    text-align: center;

    .title1 {
      font-size: 24px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 26px;
      letter-spacing: 1px;
      padding-top: 100px;
    }
    .title2 {
      font-size: 24px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 26px;
      letter-spacing: 1px;
      margin-top: 32px;
      margin-bottom: 111px;
    }
    .title3 {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      color: #999999;
      line-height: 26px;
      letter-spacing: 1px;
    }
  }
}
</style>
